import styled from "styled-components";

const WrapBox = styled.div`
  width: 100%;
  z-index: 1;
  padding-top: 101px;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  padding-bottom: 60px;
  @media screen and (max-width: 480px) {
    padding-top: 70px;
  }
`;

export default WrapBox;
