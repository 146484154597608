import { useEffect } from "react";

const { kakao } = window;

const KakaoMap = (props) => {
  useEffect(() => {
    const lat = props?.lat;
    const lng = props?.lng;
    const churchName = props?.churchName;
    if (churchName === undefined || churchName === null || churchName === "") return;
    if (lat === undefined || lat === null) return;
    if (lng === undefined || lng === null) return;

    const container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
    const options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new kakao.maps.LatLng(lat, lng), //지도의 중심좌표.//위도 경도 순
      level: 3, //지도의 레벨(확대, 축소 정도)
    };

    const map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
    const markerPosition = new kakao.maps.LatLng(lat, lng);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);

    const iwContent =
      `<div style="padding:5px;">${churchName} <br><a href="https://map.kakao.com/link/map/${churchName},${lat},${lng}" style="color:blue" target="_blank">큰지도보기</a> <a href="https://map.kakao.com/link/to/${churchName},${lat},${lng}" style="color:blue" target="_blank">길찾기</a></div>`;
    const iwPosition = new kakao.maps.LatLng(lat, lng); //인포윈도우 표시 위치입니다

    const infowindow = new kakao.maps.InfoWindow({
      position: iwPosition,
      content: iwContent,
    });

    infowindow.open(map, marker);

    function setZoomable(zoomable) {
      map.setZoomable(zoomable);
    }
    setZoomable(false); // 확대 , 축소 못하도록

    window.addEventListener("resize", function () {
      var markerPosition = marker.getPosition();
      map.relayout();
      map.setCenter(markerPosition);
    }); // 리사이즈시 마커 중심 그대로 지정

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener("resize", function () {
        var markerPosition = marker.getPosition();
        map.relayout();
        map.setCenter(markerPosition);
      });
    };
  }, [props.churchName, props.lat, props.lng]);

  return <div id="map" style={{ width: "85%", height: "450px" }}></div>;
};

export default KakaoMap;
