import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import AppBar from "../components/Appbar";
import WrapBox from "../styled_components/WrapBox";
import Footer from "../components/FooterDiv";
import TitleDiv from "../styled_components/TitleDiv";
import LineDiv from "../styled_components/LineDiv";
import axios from "axios";

import { API_PATHNAME, churchName } from "../utils/API";

function ChurchLife() {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [maxNumPage, setMaxNumPage] = useState(1);
  const [pageRange, setPageRange] = useState({ start: 1, end: 5 });

  const getMaxPage = async () => {
    const APIURL = `${API_PATHNAME}/${churchName}/life/maxpage`;
    axios.get(APIURL).then(function (response) {
      const MaxPageURL = response.data;
      setMaxNumPage(MaxPageURL);
    });
  };

  const getBlogs = async (page) => {
    const APIURL = `${API_PATHNAME}/${churchName}/life/${page}`;
    axios.get(APIURL).then(function (response) {
      const BlogAPIURL = response.data;
      setBlogs(BlogAPIURL);
    });
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updatePageRange = useCallback(
    (page) => {
      const start = Math.max(1, page - 2);
      const end = Math.min(maxNumPage, start + 4);
      setPageRange({ start, end });
    },
    [maxNumPage]
  );

  useEffect(() => {
    if (page >= 1) {
      getBlogs(page);
      moveToTop();
    }
  }, [page]);

  useEffect(() => {
    getMaxPage();
  }, []);

  useEffect(() => {
    updatePageRange(page);
  }, [page, maxNumPage, updatePageRange]);

  return (
    <>
      <WrapBox>
        <AppBar />
        <TitleDiv>
          <h1>교회 생활</h1>
        </TitleDiv>
        <LineDiv />
        <BlogListDiv>
          {blogs.length > 0 && (
            <CardListDiv>
              {blogs.map((blog) => (
                <CardDiv key={blog.id} onClick={() => window.open(blog.link)}>
                  <ThumbImg src={blog.thumbnail} alt="thumbnail" />
                  <Title>{blog.title}</Title>
                </CardDiv>
              ))}
            </CardListDiv>
          )}
        </BlogListDiv>
        <Nav>
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            이전
          </Button>
          {Array.from({ length: pageRange.end - pageRange.start + 1 }, (_, i) => (
            <Button
              key={pageRange.start + i}
              onClick={() => setPage(pageRange.start + i)}
              active={page === pageRange.start + i}
            >
              {pageRange.start + i}
            </Button>
          ))}
          <Button
            onClick={() => setPage(page + 1)}
            disabled={page === maxNumPage}
          >
            다음
          </Button>
        </Nav>
      </WrapBox>
      <Footer />
    </>
  );
}

const BlogListDiv = styled.div``;

const CardListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CardDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(100% / 4 - 40px);
  min-width: 300px;

  @media (max-width: 1024px) {
    width: calc(100% / 3 - 40px);
  }

  @media (max-width: 768px) {
    width: calc(100% / 2 - 40px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 10px;
    margin: 15px;
  }
`;

const ThumbImg = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 4px;
  object-fit: cover;
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  
  @media (max-width: 500px) {
    gap: 1px;
  }
`;

const Button = styled.button`
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${(props) => (props.active ? "blue" : "transparent")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid black;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: green;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    margin: 0 0.2rem;
  }
`;

export default ChurchLife;
