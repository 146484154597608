import styled from "styled-components";
import BannerSlider from "../components/BannerSlider";
import AppBar from "../components/Appbar";
import HomeTitleDiv from "../styled_components/HomeTitleDiv";
import WrapBox from "../styled_components/WrapBox";
import TimeTable from "../components/Table";
import Footer from "../components/FooterDiv";
import AlbumSlider from "../components/AlbumSlider";

function Home() {

  const getChurchName = () => {
    const churchName = process.env.REACT_APP_SITE_TITLE !== undefined ? process.env.REACT_APP_SITE_TITLE : "교회"
    return churchName
  }

  return (
    <>
      {<AppBar />}
      <WrapBox>
        <BannerDiv>
          <BannerSlider />
        </BannerDiv>
        <ContentsBoxDiv>
          <HomeTitleDiv>
            <h1>🙏 {getChurchName()} 앨범</h1>
          </HomeTitleDiv>
          <AlbumSlider />
        </ContentsBoxDiv>
        <ContentsBoxDiv>
          <HomeTitleDiv>
            <h1>⏱ 예배시간</h1>
          </HomeTitleDiv>
          <TimeTable />
        </ContentsBoxDiv>
      </WrapBox>
      <Footer />
    </>
  );
}

const ContentsBoxDiv = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
const BannerDiv = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

export default Home;
