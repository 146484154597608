import AppBar from "../components/Appbar";
import WrapBox from "../styled_components/WrapBox";
import Footer from "../components/FooterDiv";
import TimeTable from "../components/Table";
import TitleDiv from "../styled_components/TitleDiv";
import LineDiv from "../styled_components/LineDiv";

const Time = () => {
  return (
    <>
      <AppBar />
      <WrapBox>
        <TitleDiv>
          <h1>예배시간</h1>
        </TitleDiv>
        <LineDiv />
        <TimeTable />
      </WrapBox>
      <Footer />
    </>
  );
};



export default Time;
