import React from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

import { API_PATHNAME, churchName } from "../utils/API"

export default function BannerSlider() {
  const [banners, setBanners] = useState([]);

  const getBanner = async () => {
    const APIURL = `${API_PATHNAME}/${churchName}/banner`;
    axios
      .get(APIURL)
      .then((response) => {
        setBanners(response.data);
      })
  };
  useEffect(() => {
    getBanner();
  }, []);

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "20px",
  };

  return (
    <div>
      <Slider {...bannerSettings}>
        {banners.map((banner) => {
          return (
            <BoxDiv>
              <BannerImg src={banner.banner_img} alt="Banner" />
            </BoxDiv>
          );
        })}
      </Slider>
    </div>
  );
}

const BoxDiv = styled.div``;
const BannerImg = styled.img`
  width: 100%;
`;
