import styled from "styled-components";

const HomeTitleDiv = styled.div`
  width: 100%;
  margin-top: 100px;
  color: #3c4554;
  font-size: 18px;
  @media screen and (max-width: 480px) {
    font-size: 12px;
    margin-left: 15px;
  }

`;

export default HomeTitleDiv;
